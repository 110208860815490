$primary: #002957;
$primary-dark: darken($primary, 10%);
$primary-light: #5383ad;

$secondary: #c39428;
$gray-600: #a7a8aa;

$red: #952337;
$green: #00806e;
$gray: gray; // gray is the correct spelling

.bg-gray {
  background-color: $gray-600;
}
