html,
body,
app-root {
  height: 100%;
  margin: 0;
}

body {
  overflow: hidden;
}

$bg-primary-dark: darken($primary, 10%);

.bg-primary-dark {
  background-color: $bg-primary-dark;
}

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/Segoe UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/Segoe UI Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/Segoe UI Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/Segoe UI Bold Italic.woff") format("woff");
}

body {
  font-family: "Segoe UI Regular";
}

json-object json-object > div:first-child {
  margin: -5px;
  margin-left: 0;
  margin-right: 0;
  // background-color: rgba(0, 0, 0, 0.02);
}
