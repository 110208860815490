@import "variables";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "style";

* .json-object:not(:first-child) {
  @extend .border;
}

* .json-object:not(:first-child) {
  background-color: rgba(0, 0, 0, 0.02);
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}
